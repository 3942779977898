import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import BarChart from "../../components/chart/BarChart";

import { Grid } from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Check,
  Edit as EditIcon,
} from "@material-ui/icons";

import {
  createCampaign,
  clearCustomQuestionStorage,
  clearExamsetStorage,
  addInviteStorage,
  createInvitation,
  getCampaignById,
  addExamsetStorage,
  addCustomQuestionStorage,
} from "redux/actions/campaign";

import Checkbox from "@mui/material/Checkbox";

import { uploadImage } from "redux/actions/share";
import ButtonUploadFile from "components/form/ButtonUploadFile";
import Date from "components/form/Date";
import Table from "components/table/Table";
import { Autocomplete } from "@mui/material";

import {
  selectMasterJob,
  selectMasterJobLevel,
  selectMasterOrganization,
} from "../../redux/selectors";
import PopupAddTestExam from "containers/popup/PopupAddTestExam";
import PopupAddCustomQuestion from "containers/popup/PopupAddCustomQuestion";
import PopupEditInvite from "containers/popup/PopupEditInvite";
import PopupUploadInviteCSV from "containers/popup/PopupUploadInviteCSV";

import imageChart from "assets/images/mock-chart.png";
import PopupEditAssessment from "containers/popup/PopupEditAssessment";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 550px;
          min-width: 228px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          /* min-width: 150px; */
          height: 47px;
        }
      }
    }
  }

  .choice_container {
    display: flex;
    margin-bottom: 5px;

    .choice_selection {
      margin-right: 5px;
    }
    .choice_field {
      min-width: 550px;
      margin-right: 5px;
    }
    .choice_btn {
    }
  }

  .text-right {
    text-align: right;
  }
`;

const initInvitation = {
  email: "",
  first_name: "",
  last_name: "",
};

const campaignTypList = [
  { value: "evaluate", text: "Evaluate" },
  { value: "recruitment", text: "Recruitment" },
];

const campaignTargetList = [
  { value: "internal", text: "Internal" },
  { value: "external", text: "External" },
];

const initCustomQuestion = {
  campaign_name: "",
  job_role_id: null,
  job_role_level: null,
  duration_time: 60,
  campaign_start_date: "2022-07-08 08:00:00",
  campaign_end_date: "2022-07-08 23:00:00",
  campaign_type: "",
  use_in: "",
  campaign_detail: "",
  is_publish: 1,
  vdo_url: "",
  vdo_embed: "",
  is_show_vdo: false,
  is_snapshot: true,
  extra_time: 10,
  organization_id: 1,
  exam_set: [],
  custom_question: [
    {
      question_type: "multiple-choice",
      question_time: 2,
      video_url: "",
      image_url: "",
      question_title: "ถ้าเลือกได้ คุณจะเป็น Hero คนใดจากข้อต่อไปนี้",
      question_description: "ถ้าเลือกได้ คุณจะเป็น Hero คนใดจากข้อต่อไปนี้",
      choice: [
        {
          choice_no: 1,
          choice_description: "Super Man",
          choice_type: "answer",
          is_answer: false,
          answer_point: 1,
          image_url: "",
          vdo_url: "",
        },
      ],
      is_shuffle_answer: true,
      is_exist: false,
    },
    {
      question_type: "essay",
      question_time: 5,
      video_url: "",
      image_url: "",
      question_title: "มีความคิดเห็นยังไงกับ work from home",
      question_description: "มีความคิดเห็นยังไงกับ work from home",
      choice: [],
      is_shuffle_answer: false,
      is_exist: false,
    },
  ],
};

const CampaignManagementEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const masterDataJob = useSelector(selectMasterJob);
  const masterDataJobLevel = useSelector(selectMasterJobLevel);
  const masterDataOrganization = useSelector(selectMasterOrganization);
  const {
    examsetStorage,
    customQuestionStorage,
    inviteStorage,
    campaignCreateResponse,
    campaignDetail,
  } = useSelector((state) => state.campaign);

  const [from, setFrom] = useState(initCustomQuestion);
  const [invitationFrom, setInvitationFrom] = useState(initInvitation);
  const [dataJobs, setDataJobs] = useState(
    masterDataJob.map((it) => ({ label: it.text, id: it.value }))
  );

  const [isOpenPopupAddTestExam, setIsOpenPopupAddTestExam] = useState(false);
  const [isOpenPopupAddCustomQuestion, setIsOpenPopupAddCustomQuestion] =
    useState(false);
  const [isOpenPopupEditInvite, setIsOpenPopupEditInvite] = useState(false);
  const [isOpenPopupOpenUploadCSVInvite, setIsOpenPopupOpenUploadCSVInvite] =
    useState(false);
  const [isOpenPopupEditAssessment, setIsOpenPopupEditAssessment] =
    useState(false);
  const [examset, setExamset] = useState([]);
  const [customQuestion, setCustomQuestion] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [invitationTable, setInvitationTable] = useState([]);
  const [inviteedit, setInviteedit] = useState();
  const [campaignId, setCampaignId] = useState();
  const [campaignData, setCampaignData] = useState();
  const [candidates, setCandidates] = useState();

  const convertTimestampToDate = () => {
    const timestamp = 1607110465663;
    const date = new Date(timestamp);
    console.log(date.getTime());
    console.log(date);
  };

  useEffect(() => {
    dispatch(getCampaignById(id));
    // convertTimestampToDate();
  }, [id]);

  useEffect(() => {
    console.log(campaignDetail);
    setCampaignData(campaignDetail);
    const examSetList = campaignDetail?.examSetList?.map((v) => ({
      createdAt: v.createdAt,
      examCampaignsId: v.exam_campaigns_id,
      name: v.exam_set_name,
      examTime: v.exam_set_time,
      examSetTopic: v.exam_set_topic,
      examSetsId: v.exam_sets_id,
      id: v.id,
      orderNo: v.order_no,
      presetUid: v.preset_uid,
      updatedAt: v.updatedAt,
      code: "-",
      subjectName: "-",
      amountQuestion: "-",
      author: "-",
    }));

    const customQuestion = campaignDetail?.customQuestionList?.map((v) => ({
      id: v.id,
      examCampaignsId: v.exam_campaigns_id,
      questionType: v.question_type,
      answerType: v.answer_type,
      questionTime: v.question_time,
      videoUrl: v.video_url,
      imageUrl: v.image_url,
      questionTitle: v.question_title,
      questionDescription: v.question_description,
      isShuffleAnswer: v.is_shuffle_answer,
      status: v.status,
      createdAt: v.createdAt,
      updatedAt: v.updatedAt,
      examCampaigns_custom_questions_choices:
        v.exam_campaigns_custom_questions_choices,
    }));

    dispatch(addExamsetStorage(examSetList));
    dispatch(addCustomQuestionStorage(customQuestion));

    let index = 1;
    let newItems = campaignDetail?.candidateList?.map((it) => {
      return { ...it, id: index++ };
    });

    setCandidates(newItems);
  }, [campaignDetail, dispatch]);

  useEffect(() => {
    setExamset(examsetStorage);
  }, [examsetStorage]);

  useEffect(() => {
    setCustomQuestion(customQuestionStorage);
  }, [customQuestionStorage]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columsTestSet = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "code",
      headerName: "รหัสชุดข้อสอบ",
    },
    {
      field: "name",
      headerName: "ชื่อชุดข้อสอบ",
    },
    {
      field: "subjectName",
      headerName: "วิชา",
    },
    {
      field: "amountQuestion",
      headerName: "จำนวนข้อสอบ",
    },
    {
      field: "examTime",
      headerName: "เวลา (min)",
    },
    {
      field: "author",
      headerName: "Author",
    },
  ];

  const columsCustomQuestion = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "questionTitle",
      headerName: "ชื่อชุดข้อสอบ",
      width: 500,
    },
    {
      field: "questionType",
      headerName: "Question Type",
    },
    {
      field: "questionTime",
      headerName: "เวลา (min)",
    },
    // {
    //   field: "author",
    //   headerName: "Author",
    // },
  ];

  const columsCandidate = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "start_time",
      headerName: "Start Time",
      renderCell: IsNullValue,
    },
    {
      field: "end_time",
      headerName: "Leave Time",
      renderCell: IsNullValue,
    },
    {
      field: "submitted",
      headerName: "Submitted",
      renderCell: IsNullValue,
    },
    {
      field: "time_in",
      headerName: "Time in Session",
      renderCell: IsNullValue,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: IsNullValue,
    },
  ];

  const handleOpenPopupAddTestExam = () => {
    setIsOpenPopupAddTestExam(true);
  };

  const handleClosePopupAddTestExam = () => {
    setIsOpenPopupAddTestExam(false);
  };

  const handleOpenPopupAddCustomQuestion = () => {
    setIsOpenPopupAddCustomQuestion(true);
  };

  const handleClosePopupAddCustomQuestion = () => {
    setIsOpenPopupAddCustomQuestion(false);
  };

  const handleOpenPopupEditAssessment = () => {
    setIsOpenPopupEditAssessment(true);
  };

  const handleClosePopupEditAssessment = () => {
    setIsOpenPopupEditAssessment(false);
    dispatch(getCampaignById(id));
  };

  const handleSave = () => {
    const exam_set = examset?.map((it, index) => ({
      exam_sets_id: it.id,
      order_no: index++,
      exam_set_time: it.examTime,
    }));

    from.exam_set = exam_set;

    dispatch(createCampaign(from)).then(() => {
      dispatch(clearCustomQuestionStorage());
      dispatch(clearExamsetStorage());
      setFrom(initCustomQuestion);
    });
  };

  const handleAddInvitation = () => {
    setInvitations((prev) => [...prev, invitationFrom]);
    setInvitationFrom(initInvitation);
  };

  useEffect(() => {
    console.log("inviteStorage", inviteStorage);
    if (invitations.length === 0) {
      setInvitations(inviteStorage);
    } else {
      let arr = [...invitations];
      for (const iterator of inviteStorage) {
        arr.push(iterator);
      }
      setInvitations(arr);
    }
  }, [inviteStorage]);

  useEffect(() => {
    console.log(invitations);
    let index = 1;
    let newItems = invitations.map((it) => {
      return { ...it, no: index++ };
    });
    setInvitationTable(newItems);
  }, [invitations]);

  const handleEditNvite = (row) => {
    console.log(row);
    setInviteedit(row);
    setIsOpenPopupEditInvite(true);
  };

  const handleClosePopupEditInvite = (row) => {
    setIsOpenPopupEditInvite(false);
  };

  const handleDeleteNvite = (value) => {
    const newData = invitationTable.filter((it) => it.id !== value?.id);
    setInvitationTable(newData);
    setInvitations(
      newData.map((it) => ({
        first_name: it.first_name,
        last_name: it.last_name,
        email: it.email,
      }))
    );
  };

  const handleSaveInvitation = () => {
    let obj = {
      campaigns_id: campaignId,
      examer: invitations,
    };

    console.log(obj);
    dispatch(createInvitation(obj));
  };

  const handleOpenUploadCSV = () => {
    setIsOpenPopupOpenUploadCSVInvite(true);
  };

  const handleCloseUploadCSV = () => {
    setIsOpenPopupOpenUploadCSVInvite(false);
  };

  useEffect(() => {
    console.log(campaignCreateResponse);
    setCampaignId(campaignCreateResponse?.id);
  }, [campaignCreateResponse]);

  const handleDeleteTestSet = () => {};
  const handleDeleteCustomQuestion = () => {};

  return (
    <SidebarLayout title="Examination">
      {Boolean(isOpenPopupAddTestExam) && (
        <PopupAddTestExam open onClose={handleClosePopupAddTestExam} />
      )}
      {Boolean(isOpenPopupAddCustomQuestion) && (
        <PopupAddCustomQuestion
          open
          onClose={handleClosePopupAddCustomQuestion}
        />
      )}
      {Boolean(isOpenPopupEditInvite) && (
        <PopupEditInvite
          open
          inviteedit={inviteedit}
          // index={}
          onClose={handleClosePopupEditInvite}
        />
      )}
      {Boolean(isOpenPopupEditAssessment) && (
        <PopupEditAssessment
          open
          inviteedit={inviteedit}
          onClose={handleClosePopupEditAssessment}
        />
      )}
      {Boolean(isOpenPopupOpenUploadCSVInvite) && (
        <PopupUploadInviteCSV open onClose={handleCloseUploadCSV} />
      )}
      <Div>
        <SubMenu
          menu={[{ path: "/campaignMngt/all", text: "Campaign Management" }]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <Grid container spacing={5}>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <BackIcon
                  className="back-icon"
                  onClick={() => history.goBack()}
                />
                <span className="title">{campaignData?.campaign_name}</span>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ padding: "0 45px" }}>
                    <div style={{ textAlign: "right" }}>
                      <EditIcon
                        className="end clickable"
                        onClick={() => handleOpenPopupEditAssessment()}
                      />
                    </div>
                    <table width={"100%"}>
                      <tr>
                        <td>บริษัท:</td>
                        <td className="text-right">
                          {campaignData?.project || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>ตำแหน่ง:</td>
                        <td className="text-right">
                          {campaignData?.job_position || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>ประเภท:</td>
                        <td className="text-right">
                          {campaignData?.campaign_type || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Campaign Start:</td>
                        <td className="text-right">
                          {campaignData?.campaign_start_date}
                        </td>
                      </tr>
                      <tr>
                        <td>Campaign Finish:</td>
                        <td className="text-right">
                          {campaignData?.campaign_end_date}
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td className="text-right">{campaignData?.status}</td>
                      </tr>
                    </table>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <table width={"55%"}>
                      <tr>
                        <td>จำนวนผู้เข้าสอบทั้งหมด:</td>
                        <td> {campaignData?.count_invite || "-"}</td>
                      </tr>
                      <tr>
                        <td>จำนวนคนที่ทำข้อสอบแล้ว:</td>
                        <td> {campaignData?.count_examer || "-"}</td>
                      </tr>
                      <tr>
                        <td>(%) ทำข้อสอบแล้ว:</td>
                        <td> {campaignData?.percent}</td>
                      </tr>
                    </table>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <span className="title">ข้อมูลเกี่ยวกับข้อสอบ</span>
                <div className="section-group">
                  <div className="group-field end">
                    <Button onClick={() => handleOpenPopupAddTestExam()}>
                      Add Test Set
                    </Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <Table
                      columns={columsTestSet}
                      rows={examset}
                      onDeleteRow={handleDeleteTestSet}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <span className="title">คำถามเพิ่มเติม</span>
                <div className="section-group">
                  <div className="group-field end">
                    <Button onClick={() => handleOpenPopupAddCustomQuestion()}>
                      Add Custom Question
                    </Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <Table
                      columns={columsCustomQuestion}
                      rows={customQuestion}
                      onDeleteRow={handleDeleteCustomQuestion}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <span className="title">Histrogram Distribution</span>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <BarChart dataResult={candidates} />
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex - start",
                        height: "355px",
                      }}
                    >
                      <div>
                        <strong>หัวข้อทดสอบ</strong>
                      </div>
                      <br />
                      {campaignData?.examSetList?.map((it) => (
                        <div>
                          <div>
                            <strong>{it.exam_set_name}</strong>
                          </div>
                          <div>{it.exam_set_topic}</div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <br />
                <span className="title">Candidate Name List</span>
                <div className="section-group">
                  <div className="group-field end">
                    <Button outlined onClick={() => handleOpenUploadCSV()}>
                      Download CSV
                    </Button>
                    <Button>Search</Button>
                    <Button>Invite</Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <Table
                      columns={columsCandidate}
                      rows={candidates}
                      // onDeleteRow={handleDeleteCustomQuestion}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default CampaignManagementEdit;
