import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Collapse from "components/surfaces/Collapse";
import TextFieldArea from "components/form/TextFieldArea";
import { ArrowBack as BackIcon } from "@material-ui/icons";

import { getDiscResults } from "redux/actions/report";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .group-title {
        font-size: 32px;
        font-weight: bold;
        margin-top: 16px;
        min-width: 65px;
        text-align: center;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-left: 10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .btn_back {
    margin-right: 20px;
    cursor: pointer;
  }

  .btn-container {
    display: flex;
    margin-bottom: 40px;

    justify-content: space-between;
    .btn-item {
      width: 125px;
    }
  }

  .detail-container {
    width: 100%;
    max-width: 100%;
    .detail-title {
      margin: 10px 0;
      font-size: 22px;
      font-weight: bold;
    }
  }
`;

// const mockTargerDISCList = [
//   {
//     name: "C",
//     character: "Objective Thinker",
//   },
//   {
//     name: "CS",
//     character: "The Perfectionist",
//   },
//   {
//     name: "C",
//     character: "The Innovator",
//   },
//   {
//     name: "CI",
//     character: "Practicsioner",
//   },
//   {
//     name: "D",
//     character: "The Opportunist",
//   },
//   {
//     name: "DI",
//     character: "The Mobilizer",
//   },
//   {
//     name: "DS",
//     character: "The Producer",
//   },
//   {
//     name: "DC",
//     character: "The pioneer",
//   },
//   {
//     name: "I",
//     character: "The Socializer",
//   },
//   {
//     name: "IC",
//     character: "The Strategist",
//   },
//   {
//     name: "ID",
//     character: "The Persuader",
//   },
//   {
//     name: "IS",
//     character: "The Coach",
//   },
//   {
//     name: "S",
//     character: "The Supporter",
//   },
//   {
//     name: "SC",
//     character: "The Researcher",
//   },
//   {
//     name: "SD",
//     character: "The Go-Getter",
//   },
//   {
//     name: "SI",
//     character: "The Harmonizer",
//   },
//   {
//     name: "Re",
//     character: "Retake Self-assessment",
//   },
// ];

const ConfigurationDISC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { discs } = useSelector((state) => state.report);

  const [discList, setDiscList] = useState(discs);

  const submitCreateConfigurationReport = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getDiscResults());
  }, []);

  useEffect(() => {
    setDiscList(discs);
  }, [discs]);

  return (
    <SidebarLayout title="Configuration Report">
      <Div>
        <form onSubmit={submitCreateConfigurationReport}>
          <div className="content-wrapper">
            <div className="form-wrapper">
              <div className="selection-group">
                <div className="btn-container">
                  <div className="btn-item">
                    <BackIcon
                      className="btn_back"
                      onClick={() => history.goBack()}
                    />
                  </div>
                  <div className="title">DISC Configuration</div>
                  <div className="btn-item">
                    <Button>Save</Button>
                  </div>
                </div>
              </div>
              <br />
              {discList.map((item, index) => (
                <Collapse
                  key={index}
                  label={
                    <div className="section-group">
                      <div className="group-title">{item?.id}</div>
                      <div className="group-field">
                        <TextField
                          className="field_reportname"
                          value={item?.discCharacter}
                        />
                      </div>
                    </div>
                  }
                  id={`target_${index + 1}`}
                >
                  <div className="detail-container">
                    <div className="detail-title">Medium Description</div>
                    <TextFieldArea
                      className="field_reportname"
                      value={item?.discDescriptionMedium}
                      disabled
                    />
                    <div className="detail-title">Full Description</div>
                    <TextFieldArea
                      className="field_reportname"
                      value={item?.discDescriptionFull}
                      disabled
                    />
                  </div>
                </Collapse>
              ))}
            </div>
          </div>
        </form>
      </Div>
    </SidebarLayout>
  );
};

export default ConfigurationDISC;
