import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";
import Table from "components/table/Table";
import Grid from "@mui/material/Grid";

import { ArrowBack as BackIcon } from "@material-ui/icons";

import ConditionsInterviewScoreForm from "components/form/configurationReport/ConditionsInterviewScoreForm";
import ConditionsInterviewForm from "components/form/configurationReport/ConditionsInterviewForm";
import SubjectPanel from "containers/SubjectPanel";
import SubjectFixPanel from "containers/SubjectFixPanel";

import {
  createReportConfig,
  getReportConfigById,
  updateReportConfig,
} from "redux/actions/report";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section-group {
      margin-bottom: 36px;

      &.row {
        display: flex;
        flex-direction: row;
        justify-content: initial;
        column-gap: 10px;
      }

      &.center {
        text-align: center;
      }
      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .title {
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  /* button {
    width: 100%;
  } */

  .btn_back {
    margin-right: 20px;
  }

  .skill-level-container {
    display: flex;
    flex: 1 0 auto;

    .skill-level-item {
      margin: 0 8px;
      height: auto;
      min-width: 282px;
    }

    .skill-box {
      width: 50px;
      height: 50px;
      border-radius: 8px;

      input[type="color"] {
        width: 100%;
        height: 100%;
        border: 1px;
        padding: 0;
      }
    }

    .MuiFormControl-root.sc-gsDKAQ.eTFNgJ.field_skillstatus.MuiFormControl-fullWidth {
      max-width: 120px;
      width: 100%;
      margin: 0 8px;
    }
  }

  .green {
    border: 1px solid green;
    background-color: green;
  }

  .red {
    border: 1px solid red;
    background-color: red;
  }

  .yellow {
    border: 1px solid #fac213;
    background-color: #fac213;
  }
  .custom-line {
    width: 30%;
    border: 1px solid #b6b8c1;
  }

  .input_skill-status {
    width: 100px;
    margin: 0 8px;
  }

  .btn-add {
    width: 200px;
  }
  .btn-submit {
    width: 200px;
    margin-left: 10px;
  }
`;

const initialFormResult = {
  name: "",
  min_score: "",
  max_score: "",
  operation: "",
  description: "",
};

const initialSubjectList = [
  initialFormResult,
  initialFormResult,
  initialFormResult,
];

const initialFormSubject = {
  subject_id: "",
  weight: "",
  operation: "",
  report_subject_result_mappings: initialSubjectList,
};

const initialInterviewForm = {
  interview_condition_id: "",
  operation: "",
  score: "",
};

const initialDISCFORM = {
  disc_target: "",
  is_disc: false,
};

const initialSkillLevelForm = {
  name: "",
  color: "",
  description: "",
};

const initialSkillLevelList = [
  initialSkillLevelForm,
  initialSkillLevelForm,
  initialSkillLevelForm,
];

const ConfigurationReportCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const { reportConfigData } = useSelector((state) => state.report);
  const { companyData } = useSelector((state) => state.masterData);

  const [reportConfigDataEdit, setReportConfigDataEdit] =
    useState(reportConfigData);
  const [reportName, setReportName] = useState(
    reportConfigDataEdit?.name || ""
  );
  const [companyName, setCompanyName] = useState("");

  const [formIsHaveDISC, setFormIsHaveDISC] = useState(initialDISCFORM);
  const [subjectFormList, setSubjectFormList] = useState([initialFormSubject]);

  const [interviewFormList, setInterviewFormList] = useState(
    reportConfigData?.report_interview_conditions || [initialInterviewForm]
  );

  const [skillLevelList, setSkillLevelList] = useState(initialSkillLevelList);

  const [recommendationForm, setRecommendationForm] =
    useState(initialSubjectList);
  const [percentileForm, setPercentile] = useState(initialSubjectList);
  const [matchingScoreForm, setMatchingScoreForm] =
    useState(initialSubjectList);

  useEffect(() => {
    if (type === "edit") {
      dispatch(getReportConfigById(id));
      setSkillLevelList(reportConfigData?.report_skill_levels);
      setSubjectFormList(reportConfigData?.report_subjects);
      setRecommendationForm(
        reportConfigDataEdit?.report_maching_score_recommend_mappings
      );
      setPercentile(
        reportConfigDataEdit?.report_percentile_report_subject_result_mappings
      );
      setMatchingScoreForm(
        reportConfigDataEdit?.report_maching_score_report_subject_result_mappings
      );
      setSkillLevelList(reportConfigData?.skill_level);
    }
  }, []);

  useEffect(() => {
    setReportConfigDataEdit(reportConfigData);
  }, [reportConfigData]);

  const handleAddSubject = (e) => {
    setSubjectFormList([...subjectFormList, initialFormSubject]);
  };

  const handleAddInterview = (e) => {
    setInterviewFormList([...interviewFormList, initialInterviewForm]);
  };

  const handleChangeSubjectList = (e, indexSubject = 0) => {
    e.persist();
    const { name, value } = e.target;
    let newList = subjectFormList?.map((item) => item);

    newList[indexSubject] = {
      ...newList[indexSubject],
      [name]: value,
    };

    setSubjectFormList(newList);
  };

  const handleDeleteSubjectList = (indexOfForm) => {
    let newList = subjectFormList?.map((item) => item);

    if (indexOfForm > -1) {
      newList.splice(indexOfForm, 1); // 2nd parameter means remove one item only
    }
    console.log("newList", newList);

    setSubjectFormList(newList);
  };

  const handleChangeInterviewList = (e, indexInterview = 0) => {
    e.persist();
    const { name, value } = e.target;
    let newList = interviewFormList?.map((item) => item);

    newList[indexInterview] = {
      ...newList[indexInterview],
      [name]: value,
    };

    setInterviewFormList(newList);
  };

  const handleDeleteIntervicewList = (indexOfForm) => {
    let newList = interviewFormList?.map((item) => item);

    if (indexOfForm > -1) {
      newList.splice(indexOfForm, 1); // 2nd parameter means remove one item only
    }
    console.log("newList", newList);

    setInterviewFormList(newList);
  };

  const handleSetDataSubjectDetail = (indexSubject = 0, data) => {
    let newList = subjectFormList?.map((item) => item);

    newList[indexSubject] = {
      ...newList[indexSubject],
      report_subject_result_mappings: data,
    };

    setSubjectFormList(newList);
  };

  const handleChangeSubjectResultList = (
    e,
    indexSubject = 0,
    indexItem = 0
  ) => {
    e.persist();
    const { name, value } = e.target;
    let newList = [...subjectFormList];
    let newItem = [...newList[indexSubject]?.report_subject_result_mappings];

    newItem[indexItem] = {
      ...newItem[indexItem],
      [name]: value,
    };

    newList[indexSubject] = {
      ...newList[indexSubject],
      report_subject_result_mappings: newItem,
    };

    setSubjectFormList(newList);
  };

  const handleChangeDISC = (e) => {
    const { name, value } = e.target;

    if (value) {
      setFormIsHaveDISC({
        [name]: value,
        is_disc: true,
      });
    }
  };

  const handleChangeSkillLevel = (index, e) => {
    console.log(e.targer);
    const { name, value } = e.target;

    let prevState = [...skillLevelList];
    prevState[index] = {
      ...prevState[index],
      [name]: value,
    };
    console.log("prevState", prevState);
    setSkillLevelList(prevState);
  };

  const mappingFormCreateConfigReport = () => {
    const { email } = JSON.parse(localStorage.getItem("user"));
    let form = {
      name: reportName,
      company_id: companyName,
      createdBy: email,
      ...formIsHaveDISC,
      subject_mapping: subjectFormList?.map((it) => {
        return {
          subject_id: +it.subject_id,
          weight: +it.weight,
          result_mapping: it.report_subject_result_mappings,
        };
      }),
      maching_score_recommend: recommendationForm,
      percentile_score_mapping: percentileForm,
      maching_score_mapping: matchingScoreForm,
      interview_condition: interviewFormList,
      skill_level: skillLevelList,
    };
    return form;
  };

  const submitCreateConfigurationReport = (e) => {
    e.preventDefault();

    if (type === "edit") {
      const { email } = JSON.parse(localStorage.getItem("user"));
      let obj = {
        name: reportName,
        company_id: companyName,
        updatedBy: email,
        disc_target: formIsHaveDISC?.disc_target || "",
        is_disc: formIsHaveDISC?.is_disc || false,
        report_subjects:
          subjectFormList?.map((it) => {
            return { id: +it.id || +it.subject_id, weight: +it.weight };
          }) || [],
        report_interview_conditions:
          interviewFormList?.map((it) => {
            return {
              id: +it.id,
              operation: it.operation,
              score: +it.score,
            };
          }) || [],
        skill_level: skillLevelList,
      };

      console.log(obj);
      dispatch(updateReportConfig(obj, id)).then(() =>
        history.push("/configuration-report/all")
      );
    } else {
      let finalFormData = mappingFormCreateConfigReport();
      console.log("finalFormData", finalFormData);
      dispatch(createReportConfig(finalFormData)).then((err) => {
        if (!err) {
          history.push("/configuration-report/all");
        }
      });
    }
  };

  return (
    <SidebarLayout title="Configuration Report">
      <Div>
        <form onSubmit={submitCreateConfigurationReport}>
          <div className="content-wrapper">
            <div className="form-wrapper">
              <div className="selection-group">
                <Grid container spacing={2}>
                  <Grid item xs>
                    <div className="title">
                      <BackIcon
                        className="btn_back"
                        onClick={() => history.goBack()}
                      />
                      {type === "create" ? "Create" : "Edit"} Quotation
                    </div>
                  </Grid>
                </Grid>
              </div>
              <br />
              <div className="section-group row">
                <div>
                  <div className="group-title">Report Name</div>
                  <div className="group-field">
                    <TextField
                      className="field_reportname"
                      onChange={(e) => setReportName(e.target.value)}
                      value={reportName}
                    />
                  </div>
                </div>

                <div>
                  <div className="group-title">Company</div>
                  <div className="group-field">
                    <Select
                      items={companyData}
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      defaultValue="เลือกบริษัท"
                      className="field-tag"
                      // errorText={errors.tag}
                      // isError={errors.tag}
                    />
                  </div>
                </div>
              </div>
              <div className="section-group">
                <div className="group-title">ระดับความสามารถ</div>
                <div className="group-field">
                  <div className="skill-level-container">
                    <div className="input_skill-status">
                      <TextField
                        placeholder="ระดับ"
                        className="field_skillstatus"
                        value={skillLevelList?.[0]?.name}
                        name="name"
                        onChange={(e) => handleChangeSkillLevel(0, e)}
                        // disabled
                      />
                    </div>
                    <div className="skill-box ">
                      <input
                        value={skillLevelList?.[0]?.color}
                        name="color"
                        onChange={(e) => handleChangeSkillLevel(0, e)}
                        type="color"
                      />
                    </div>
                    <div className="skill-level-item">
                      <TextField
                        value={skillLevelList?.[0]?.description}
                        placeholder="รายละเอียดความสามารถ"
                        name="description"
                        onChange={(e) => handleChangeSkillLevel(0, e)}
                        className="field_skilldetail"
                        // disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="group-field">
                  <div className="skill-level-container">
                    <div className="input_skill-status">
                      <TextField
                        value={skillLevelList?.[1]?.name}
                        placeholder="ระดับ"
                        name="name"
                        onChange={(e) => handleChangeSkillLevel(1, e)}
                        className="field_skillstatus"
                        // disabled
                      />
                    </div>

                    <div className="skill-box ">
                      <input
                        value={skillLevelList?.[1]?.color}
                        name="color"
                        onChange={(e) => handleChangeSkillLevel(1, e)}
                        type="color"
                      />
                    </div>
                    <div className="skill-level-item">
                      <TextField
                        value={skillLevelList?.[1]?.description}
                        placeholder="รายละเอียดความสามารถ"
                        name="description"
                        onChange={(e) => handleChangeSkillLevel(1, e)}
                        className="field_skilldetail"
                        // disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="group-field">
                  <div className="skill-level-container">
                    <div className="input_skill-status">
                      <TextField
                        value={skillLevelList?.[2]?.name}
                        placeholder="ระดับ"
                        name="name"
                        onChange={(e) => handleChangeSkillLevel(2, e)}
                        className="field_skillstatus"
                        // disabled
                      />
                    </div>
                    <div className="skill-box ">
                      <input
                        value={skillLevelList?.[2]?.color}
                        name="color"
                        onChange={(e) => handleChangeSkillLevel(2, e)}
                        type="color"
                      />
                    </div>
                    <div className="skill-level-item">
                      <TextField
                        value={skillLevelList?.[2]?.description}
                        placeholder="รายละเอียดความสามารถ"
                        name="description"
                        onChange={(e) => handleChangeSkillLevel(2, e)}
                        className="field_skilldetail"
                        // disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-line" />
              {subjectFormList?.map((item, index) => (
                <div className="section-group" key={index}>
                  <SubjectPanel
                    indexOfForm={index}
                    formSubjectProp={item}
                    formDISCProp={formIsHaveDISC}
                    setDataConfigSubject={handleSetDataSubjectDetail}
                    onChangeProp={handleChangeSubjectList}
                    onChangeResultProp={handleChangeSubjectResultList}
                    onChangeDISCProp={handleChangeDISC}
                    handleDelete={handleDeleteSubjectList}
                  />
                </div>
              ))}
              <div className="section-group center">
                <Button
                  className="btn-add "
                  onClick={handleAddSubject}
                  disabled={subjectFormList?.length === 4}
                >
                  เพิ่มวิชา
                </Button>
              </div>
              <hr className="custom-line" />
              <div className="section-group">
                <div className="group-title">Recomendation</div>
                <SubjectFixPanel
                  formProp={recommendationForm}
                  subjectName={"recommendation"}
                  onChange={setRecommendationForm}
                />
              </div>
              <div className="section-group">
                <div className="group-title">Percentile</div>
                <SubjectFixPanel
                  formProp={percentileForm}
                  subjectName={"percentile"}
                  onChange={setPercentile}
                />
              </div>
              <div className="section-group">
                <div className="group-title">Matching Score</div>
                <SubjectFixPanel
                  formProp={matchingScoreForm}
                  subjectName={"matching_score"}
                  onChange={setMatchingScoreForm}
                />
              </div>

              <div className="section-group">
                <div className="group-title">เงื่อนไขให้การสัมภาษณ์</div>
                {interviewFormList?.map((item, index) => (
                  <ConditionsInterviewScoreForm
                    key={index}
                    indexOfForm={index}
                    formProp={item}
                    onChange={handleChangeInterviewList}
                    handleDelete={handleDeleteIntervicewList}
                    type={type}
                  />
                ))}
              </div>
              <div className="section-group center">
                <Button
                  className="btn-add"
                  onClick={handleAddInterview}
                  disabled={interviewFormList?.length === 3}
                >
                  เพิ่มเงื่อนไข
                </Button>
              </div>
              <div className="section-group">
                <div className="group-title">เงื่อนไขให้การสัมภาษณ์</div>
                <ConditionsInterviewForm
                  formProp={
                    reportConfigDataEdit?.report_interview_condition_results
                  }
                />
              </div>

              <div className="section-group center">
                <Button
                  className="btn-add"
                  onClick={() => history.push("/configuration-report/all")}
                >
                  Cancel
                </Button>
                {type === "create" ? (
                  <Button className="btn-submit" type="submit">
                    Create Report
                  </Button>
                ) : (
                  <Button className="btn-submit" type="submit">
                    Edit Report
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Div>
    </SidebarLayout>
  );
};

export default ConfigurationReportCreate;
