import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import Grid from "@mui/material/Grid";

import Select from "components/form/Select";

import {
  addCustomQuestion,
  getAllQuestionActive,
  getCustomQuestion,
} from "redux/actions/library";
import PopupReportMemberGenerate from "./PopupReportMemberGenerate";
import TextField from "components/form/TextField";

import { uploadImage } from "redux/actions/share";
import ButtonUploadFile from "components/form/ButtonUploadFile";

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
    margin-bottom: 10px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
  .button-invite {
    width: 125px;
  }

  .field-sagmentName,
  .field-sagmentList {
    width: 100%;
  }

  .section-group {
    margin-bottom: 36px;

    .group-title {
      font-size: 20px;
      font-weight: bold;
    }

    .group-field {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 -10px;

      &.end {
        justify-content: flex-end;
      }

      > * {
        width: 260px;
        min-width: 260px;
        margin-top: 16px;
        padding: 0 10px;
      }

      button {
        margin: 0 10px;
        width: auto;
        min-width: 120px;
        height: 47px;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .question-from {
    .question-group {
      margin-bottom: 20px;
    }

    .question-title {
      margin-bottom: 5px;
    }
  }
`;

const questionTypeList = [
  { value: 1, text: "Essay" },
  { value: 2, text: "Multiple Choices" },
];

const PopupCreateCustomerQuestionEssay = ({
  open,
  onClose,
  label,
  dataEdit = {},
  maxWidth = "xl",
  error,
  uid,
  userId,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [questionTime, setQuestionTime] = useState(0);
  const [questionTitle, setQuestionTitle] = useState("");
  const [urlImage, setURLImage] = useState("");

  useEffect(() => {}, []);

  const handleConfirm = () => {
    let obj = {
      question_type: "essay",
      question_time: +questionTime,
      video_url: "",
      question_title: questionTitle,
      question_description: "",
      choice: [],
      is_shuffle_answer: true,
      is_exist: false,
      image_url: urlImage,
    };

    dispatch(addCustomQuestion(obj)).then(() => {
      dispatch(getCustomQuestion()).then(() => onClose());
    });
  };

  const handleUploadImage = (e) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setURLImage(path);
      console.log(path);
    });
  };

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs>
            <div className="title">Select Report Type</div>
            <p>
              Question Type : <strong>Essay</strong>
            </p>
            <br />
            <div className="question-from">
              <div className="question-group">
                <div className="question-title">Title Question *</div>
                <div className="question-item">
                  <TextField
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                    className="input_question_title"
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">
                  Title Question Image (if any)
                </div>
                <div className="question-item">
                  <ButtonUploadFile
                    defaultValue={urlImage}
                    id={`upload-file`}
                    onChange={(e) => handleUploadImage(e)}
                  />
                </div>
              </div>
              <div className="question-group">
                <div className="question-title">Time to answer (min)*</div>
                <div className="question-item">
                  <TextField
                    value={questionTime}
                    onChange={(e) => setQuestionTime(e.target.value)}
                    className="input_question_time"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack onClick={() => handleConfirm()} outlined>
            Create
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupCreateCustomerQuestionEssay;
