import {
  ADD_QUOTATION,
  CREATE_INVOICE,
  GET_QUOTATION_BY_ID,
  GET_INVOICE_LIST,
  GET_QUOTATION_LIST,
  GET_PRODUCT_LIST,
  CREATE_DELIVERY_NOTE,
  GET_INVOICE_BY_ID,
  CREATE_RECEIPT,
  GET_RECEIPT_BY_ID,
  GET_DELIVERY_BY_ID,
  PATCH_INVOICE_STATUS,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

const mapFilter = (v) => ({
  "date_ship[$gte]": v.fromDate || null,
  "date_ship[$lte]": v.toDate || null,
  id: v.id || null,
  product_name: v.productName || null,
  product_code: v.productCode || null,
  quotation_no: v.quotationNo || null,
});

export const getProducts =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_PRODUCT_LIST.REQUEST });
    return getAPI({
      url: "product",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_PRODUCT_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_PRODUCT_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const addQuotation = (quotation) => (dispatch) => {
  dispatch({ type: ADD_QUOTATION.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "quotation",
    data: quotation,
  })
    .then((data) =>
      dispatch({ type: ADD_QUOTATION.SUCCESS, payload: data?.id })
    )
    .catch((error) => dispatch({ type: ADD_QUOTATION.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createInvoice = (body) => (dispatch) => {
  dispatch({ type: CREATE_INVOICE.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "invoice",
    data: body,
  })
    .then((data) =>
      dispatch({ type: CREATE_INVOICE.SUCCESS, payload: data?.id })
    )
    .catch((error) => dispatch({ type: CREATE_INVOICE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createReceipt = (body) => (dispatch) => {
  dispatch({ type: CREATE_RECEIPT.REQUEST });
  dispatch(showLoading());
  return postAPI({
    url: "receipt",
    data: body,
  })
    .then((data) =>
      dispatch({ type: CREATE_RECEIPT.SUCCESS, payload: data?.quotation_id })
    )
    .catch((error) => dispatch({ type: CREATE_RECEIPT.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getQuotationById = (id) => (dispatch) => {
  dispatch({ type: GET_QUOTATION_BY_ID.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `quotation/${id}`,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_QUOTATION_BY_ID.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_QUOTATION_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getQuotations =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_QUOTATION_LIST.REQUEST });
    return getAPI({
      url: "quotation",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_QUOTATION_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_QUOTATION_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getInvoices =
  (filter = {}, skip = 0, limit = 10) =>
  (dispatch, getState) => {
    const { limit } = getState().order;
    dispatch(showLoading());
    dispatch({ type: GET_INVOICE_LIST.REQUEST });
    return getAPI({
      url: "invoice",
      params: {
        ...mapFilter(filter),
        limit: limit,
        skip: skip,
      },
    })
      .then((data) =>
        dispatch({ type: GET_INVOICE_LIST.SUCCESS, payload: data })
      )
      .catch((error) => dispatch({ type: GET_INVOICE_LIST.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getInvoiceById = (id) => (dispatch) => {
  dispatch({ type: GET_INVOICE_BY_ID.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `invoice/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_INVOICE_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_INVOICE_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getReceiptById = (id) => (dispatch) => {
  dispatch({ type: GET_RECEIPT_BY_ID.REQUEST });
  dispatch(showLoading());
  return getAPI({
    url: `receipt/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_RECEIPT_BY_ID.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_RECEIPT_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getDeliveryById = (id) => (dispatch) => {
  dispatch({ type: GET_DELIVERY_BY_ID.REQUEST });
  dispatch(showLoading());
  let body = {
    invoice_id: id,
  };

  return postAPI({
    url: `delivery_note`,
    data: body,
  })
    .then((data) => {
      console.log("data", data);
      dispatch({ type: GET_DELIVERY_BY_ID.SUCCESS, payload: data });
    })
    .catch((error) => dispatch({ type: GET_DELIVERY_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateInvoiceStatus = (obj, id) => (dispatch) => {
  dispatch({ type: PATCH_INVOICE_STATUS.REQUEST });
  dispatch(showLoading());
  return patchAPI({
    url: `invoice/${id}`,
    data: obj,
  })
    .then((data) => dispatch({ type: PATCH_INVOICE_STATUS.SUCCESS }))
    .catch((error) => dispatch({ type: PATCH_INVOICE_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
