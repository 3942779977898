import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";

import ReactHtmlParser from "react-html-parser";

import formatValue from "utils/formatValue";

import {
  getCustomQuestion,
  getAllDataExamQuestion,
} from "redux/actions/library";

import { selectMasterSubject } from "../../redux/selectors";

import StatusCell from "components/table/StatusCell";
import DeltaToHtml from "components/form/DeltaToHtml";
import { useHistory } from "react-router-dom";
import PopupCreateCustomerQuestion from "containers/popup/PopupCreateCustomerQuestion";

import { deleteCustomerQuestion } from "redux/actions/library";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 190px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiDataGrid-columnHeader {
    &.MuiDataGrid-columnHeader--alignCenter {
      padding: 0;
    }
  }
`;

const initFilter = {
  questionType: "",
  title: "",
};

const questionTypeList = [
  { value: "essay", text: "Essay" },
  { value: "multiple-choice", text: "Multiple Choices" },
];

const AdditionalQuestionAll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, total, limit, allQuestions } = useSelector(
    (state) => state.library
  );

  const [questionList, setQuestionList] = useState([]);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  const [statusList, setStatusList] = useState([]);
  const [
    isOpenPopupCreateCustomerQuestion,
    setIsOpenPopupCreateCustomerQuestion,
  ] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, no: index++ };
    });
    console.log(newItems);
    setQuestionList(newItems);
  }, [items]);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/library/question/custom/${row?.id}`}>
        {value}
      </RouterLinkCell>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ConvertHtmlToString = ({ value }) => {
    if (value === "") {
      return "-";
    }
    let isDeltaString = value?.startsWith("[");
    if (isDeltaString)
      return <div className="truncate">{<DeltaToHtml contents={value} />}</div>;

    return <div className="truncate">{ReactHtmlParser(value)}</div>;
  };

  const DateTimeDisplay = ({ value }) => {
    return `${formatValue("DateTime", value)}`;
  };

  const columns = [
    {
      field: "no",
      headerName: "No.",
      width: 100,
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
      renderCell: LinkDetailCell,
    },
    {
      field: "questionTitle",
      headerName: "Title Question",
      width: 550,
      renderCell: ConvertHtmlToString,
    },
    { field: "questionType", headerName: "Question Type", width: 200 },
    {
      field: "questionTime",
      headerName: "Time (min)",
      renderCell: IsNullValue,
    },
    {
      field: "companyName",
      headerName: "Author",
      renderCell: IsNullValue,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      renderCell: DateTimeDisplay,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getCustomQuestion(initFilter));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getCustomQuestion(filter, nextPage * limit));
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getCustomQuestion(filter));
  };

  const handleDeleteQuestion = (row) => {
    dispatch(deleteCustomerQuestion(row?.id)).then(() => handleSearch());
  };

  const handleOpenCreateCustomerQuestion = () => {
    setIsOpenPopupCreateCustomerQuestion(true);
  };

  const handleCloseCreateCustomerQuestion = () => {
    setIsOpenPopupCreateCustomerQuestion(false);
  };

  return (
    <SidebarLayout title="Additional Question">
      {Boolean(isOpenPopupCreateCustomerQuestion) && (
        <PopupCreateCustomerQuestion
          open
          onClose={() => handleCloseCreateCustomerQuestion()}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/library/examseries/all", text: "ชุดข้อสอบ" },
            { path: "/library/examination/all", text: "ข้อสอบ" },
            { path: "/library/examiner/all", text: "ผู้เข้าสอบ" },
            { path: "/library/question/all", text: "คำถามเพิ่มเติม" },
            { path: "/library/subject/all", text: "วิชา" },
          ]}
        />

        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <Select
                  label="Question Type"
                  items={questionTypeList}
                  value={filter.questionType}
                  defaultValue="สถานะ"
                  id="field-questionType"
                  onChange={(e) =>
                    handleFilterChange("questionType", e.target.value)
                  }
                />
                <TextField
                  label="Title Question"
                  value={filter.title}
                  onChange={(e) => handleFilterChange("title", e.target.value)}
                  className="field-title"
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Search</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleOpenCreateCustomerQuestion()}>
                    สร้างข้อสอบ
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={questionList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              checkboxSelection={false}
              // disableSelectionOnClick={true}
              onDeleteRow={handleDeleteQuestion}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default AdditionalQuestionAll;
