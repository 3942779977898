import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Select from "components/form/Select";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";

import { Grid } from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Check,
  Edit as EditIcon,
} from "@material-ui/icons";

import {
  createCampaign,
  clearCustomQuestionStorage,
  clearExamsetStorage,
  addInviteStorage,
  createInvitation,
} from "redux/actions/campaign";

import Checkbox from "@mui/material/Checkbox";

import { uploadImage } from "redux/actions/share";
import ButtonUploadFile from "components/form/ButtonUploadFile";
import Date from "components/form/Date";
import Table from "components/table/Table";
import { Autocomplete } from "@mui/material";
import {
  selectMasterJob,
  selectMasterJobLevel,
  selectMasterOrganization,
} from "../../redux/selectors";
import PopupAddTestExam from "containers/popup/PopupAddTestExam";
import PopupAddCustomQuestion from "containers/popup/PopupAddCustomQuestion";
import { set } from "lodash";
import PopupEditInvite from "containers/popup/PopupEditInvite";
import PopupUploadInviteCSV from "containers/popup/PopupUploadInviteCSV";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }
  .table-wrapper {
    button {
      margin: 0 10px;
      width: auto;
      min-width: 120px;
      height: 47px;
    }
  }
  .headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    height: 35px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-family: "kelson_sansbold";
  }
  .border-grid {
    border: 1px solid #000;
  }
  .fix-height {
    height: 30px;
  }
  .btn-edit {
    color: #a32330;
    border-color: #a32330;
    padding: 0 auto;
    margin: 10px 0;
    height: 40px;
    :disabled {
      opacity: 0.5;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 550px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          /* min-width: 150px; */
          height: 47px;
        }
      }
    }
  }

  .choice_container {
    display: flex;
    margin-bottom: 5px;

    .choice_selection {
      margin-right: 5px;
    }
    .choice_field {
      min-width: 550px;
      margin-right: 5px;
    }
    .choice_btn {
    }
  }
`;

const initInvitation = {
  email: "",
  first_name: "",
  last_name: "",
};

const campaignTypList = [
  { value: "evaluate", text: "Evaluate" },
  { value: "recruitment", text: "Recruitment" },
];

const campaignTargetList = [
  { value: "internal", text: "Internal" },
  { value: "external", text: "External" },
];

const initCustomQuestion = {
  campaign_name: "",
  job_role_id: "",
  job_role_level: "",
  duration_time: 60,
  campaign_start_date: "2022-07-08 08:00:00",
  campaign_end_date: "2022-07-08 23:00:00",
  campaign_type: "",
  use_in: "",
  campaign_detail: "",
  is_publish: 1,
  vdo_url: "",
  vdo_embed: "",
  is_show_vdo: false,
  is_snapshot: true,
  extra_time: 10,
  organization_id: "",
  exam_set: [],
  custom_question: [],
};

const CampaignManagementCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const masterDataJob = useSelector(selectMasterJob);
  const masterDataJobLevel = useSelector(selectMasterJobLevel);
  const masterDataOrganization = useSelector(selectMasterOrganization);

  const {
    examsetStorage,
    customQuestionStorage,
    inviteStorage,
    campaignCreateResponse,
  } = useSelector((state) => state.campaign);

  const [errorsInvitation, setErrorsInvitaion] = useState({});

  const [from, setFrom] = useState(initCustomQuestion);
  const [invitationFrom, setInvitationFrom] = useState(initInvitation);
  const [dataJobs, setDataJobs] = useState();

  const [isOpenPopupAddTestExam, setIsOpenPopupAddTestExam] = useState(false);
  const [isOpenPopupAddCustomQuestion, setIsOpenPopupAddCustomQuestion] =
    useState(false);
  const [isOpenPopupEditInvite, setIsOpenPopupEditInvite] = useState(false);
  const [isOpenPopupOpenUploadCSVInvite, setIsOpenPopupOpenUploadCSVInvite] =
    useState(false);
  const [examset, setExamset] = useState([]);
  const [customQuestion, setCustomQuestion] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [invitationTable, setInvitationTable] = useState([]);
  const [inviteedit, setInviteedit] = useState();
  const [campaignId, setCampaignId] = useState();

  useEffect(() => {
    dispatch(clearCustomQuestionStorage());
    dispatch(clearExamsetStorage());
  }, []);

  useEffect(() => {
    let mapDataJob = masterDataJob.map((it) => ({
      label: it.text,
      id: it.value,
    }));
    setDataJobs(mapDataJob);
  }, [masterDataJob]);

  useEffect(() => {
    setExamset(examsetStorage);
  }, [examsetStorage]);

  useEffect(() => {
    setCustomQuestion(customQuestionStorage);
  }, [customQuestionStorage]);

  useEffect(() => {
    if (invitations.length === 0) {
      setInvitations(inviteStorage);
    } else {
      let arr = [...invitations];
      for (const iterator of inviteStorage) {
        arr.push(iterator);
      }
      setInvitations(arr);
    }
  }, [inviteStorage]);

  useEffect(() => {
    console.log(invitations);
    let index = 1;
    let newItems = invitations.map((it) => {
      return { ...it, no: index++ };
    });
    setInvitationTable(newItems);
  }, [invitations]);

  useEffect(() => {
    console.log(campaignCreateResponse);
    setCampaignId(campaignCreateResponse?.id);
  }, [campaignCreateResponse]);

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const columsTestSet = [
    {
      field: "id",
      headerName: "ลำดับที่",
    },
    {
      field: "code",
      headerName: "รหัสชุดข้อสอบ",
    },
    {
      field: "name",
      headerName: "ชื่อชุดข้อสอบ",
    },
    {
      field: "subjectName",
      headerName: "วิชา",
    },
    {
      field: "amountQuestion",
      headerName: "จำนวนข้อสอบ",
    },
    {
      field: "examTime",
      headerName: "เวลา (min)",
    },
    {
      field: "author",
      headerName: "Author",
    },
  ];

  const columsCustomQuestion = [
    {
      field: "id",
      headerName: "ลำดับที่",
    },
    {
      field: "questionTitle",
      headerName: "รหัสชุดข้อสอบ",
    },
    {
      field: "questionType",
      headerName: "Question Type",
    },
    {
      field: "questionTime",
      headerName: "เวลา (min)",
    },
    // {
    //   field: "author",
    //   headerName: "Author",
    // },
  ];

  const columsInvitation = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 80,
    },
    {
      field: "first_name",
      headerName: "Name",
    },
    {
      field: "last_name",
      headerName: "Last Name",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: IsNullValue,
    },
  ];

  const handleCheckboxChange = (key, value) => {
    setFrom({
      ...from,
      [key]: !value,
    });
  };

  const handleUploadImage = (e, key) => {
    e.persist();
    dispatch(uploadImage(e.target.files[0])).then(({ path }) => {
      setFrom({
        ...from,
        [key]: path,
      });
    });
  };

  const handleChangeFrom = (key, value) => {
    setFrom({
      ...from,
      [key]: value,
    });
  };

  const handleChangeInvitationFrom = (key, value) => {
    setInvitationFrom({
      ...invitationFrom,
      [key]: value,
    });
    validateInvitation({ [key]: value });
  };

  const handleOpenPopupAddTestExam = () => {
    setIsOpenPopupAddTestExam(true);
  };

  const handleClosePopupAddTestExam = () => {
    setIsOpenPopupAddTestExam(false);
  };

  const handleOpenPopupAddCustomQuestion = () => {
    setIsOpenPopupAddCustomQuestion(true);
  };

  const handleClosePopupAddCustomQuestion = () => {
    setIsOpenPopupAddCustomQuestion(false);
  };

  const handleSave = () => {
    const exam_set = examset?.map((it, index) => ({
      exam_sets_id: it.id,
      order_no: index++,
      exam_set_time: it.examTime,
    }));

    from.exam_set = exam_set;
    from.custom_question = customQuestion.map((it) => ({
      question_type: it.questionType,
      question_time: it.questionTime,
      video_url: it.videoUrl,
      image_url: it.imageUrl,
      question_title: it.questionTitle,
      question_description: it.questionDescription,
      choice: it.choice,
      is_shuffle_answer: it.isShuffleAnswer,
      is_exist: false,
    }));

    dispatch(createCampaign(from)).then(() => {});
  };

  const handleAddInvitation = () => {
    let isValidate = validateInvitation();
    if (isValidate) {
      setInvitations((prev) =>
        [...prev, { ...invitationFrom }].map((it, index) => ({
          id: index + 1,
          ...it,
        }))
      );
      setInvitationFrom(initInvitation);
    }
  };

  const validateInvitation = (
    fieldValues = {
      ...invitationFrom,
    }
  ) => {
    let temp = { ...errorsInvitation };

    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name !== "" ? "" : "กรุณาชื่อ";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name !== "" ? "" : "กรุณานามสกุล";
    if ("email" in fieldValues)
      temp.email = fieldValues.email !== "" ? "" : "กรุณาอีเมล";

    setErrorsInvitaion({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleEditNvite = (row) => {
    setInviteedit(row);
    setIsOpenPopupEditInvite(true);
  };

  const handleClosePopupEditInvite = (row) => {
    setIsOpenPopupEditInvite(false);
  };

  const handleDeleteNvite = (value) => {
    const newData = invitationTable.filter((it) => it.id !== value?.id);
    setInvitationTable(newData);
    setInvitations(
      newData.map((it) => ({
        first_name: it.first_name,
        last_name: it.last_name,
        email: it.email,
      }))
    );
  };

  const handleSaveInvitation = () => {
    let obj = {
      campaigns_id: 85,
      // campaigns_id: campaignId,
      examer: invitations,
    };

    console.log(obj);
    dispatch(createInvitation(obj)).then((res) => {
      console.log("res", res);
      if (!res?.error) {
        dispatch(clearCustomQuestionStorage());
        dispatch(clearExamsetStorage());
        setFrom(initCustomQuestion);
        history.goBack();
      } else {
        alert(res?.error);
      }
    });
  };

  const handleOpenUploadCSV = () => {
    setIsOpenPopupOpenUploadCSVInvite(true);
  };

  const handleCloseUploadCSV = () => {
    setIsOpenPopupOpenUploadCSVInvite(false);
  };

  return (
    <SidebarLayout title="Examination">
      {Boolean(isOpenPopupAddTestExam) && (
        <PopupAddTestExam open onClose={handleClosePopupAddTestExam} />
      )}
      {Boolean(isOpenPopupAddCustomQuestion) && (
        <PopupAddCustomQuestion
          open
          onClose={handleClosePopupAddCustomQuestion}
        />
      )}
      {Boolean(isOpenPopupEditInvite) && (
        <PopupEditInvite
          open
          inviteedit={inviteedit}
          invitationTable={invitationTable}
          setInvitations={setInvitations}
          setInvitationTable={setInvitationTable}
          // index={}
          onClose={handleClosePopupEditInvite}
        />
      )}
      {Boolean(isOpenPopupOpenUploadCSVInvite) && (
        <PopupUploadInviteCSV open onClose={handleCloseUploadCSV} />
      )}
      <Div>
        <SubMenu
          menu={[{ path: "/campaignMngt/all", text: "Campaign Management" }]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <Grid container spacing={5}>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <BackIcon
                  className="back-icon"
                  onClick={() => history.goBack()}
                />
                <span className="title">Create Assessment</span>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <div className="section-group">
                    <div className="group-field">
                      <TextField
                        label={"Campaign Name *"}
                        value={from?.campaign_name}
                        onChange={(e) =>
                          handleChangeFrom("campaign_name", e.target.value)
                        }
                      />
                      <Select
                        label={"Organization *"}
                        value={from?.organization_id}
                        items={masterDataOrganization}
                        onChange={(e) =>
                          handleChangeFrom("organization_id", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="section-group">
                    <div className="group-field">
                      <Autocomplete
                        disablePortal
                        options={dataJobs}
                        sx={{ width: 300 }}
                        onChange={(e, value) =>
                          handleChangeFrom("job_role_id", value?.id)
                        }
                        id="field-job"
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.id}>
                            <span>{option.label}</span>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="งานตำแหน่ง" />
                        )}
                      />
                      <Select
                        label={"Job Level *"}
                        value={from?.job_role_level}
                        items={masterDataJobLevel}
                        onChange={(e) =>
                          handleChangeFrom("job_role_level", +e.target.value)
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="section-group">
                    <div className="group-field">
                      <Select
                        label={"Campaign Type *"}
                        value={from?.campaign_type}
                        items={campaignTypList}
                        onChange={(e) =>
                          handleChangeFrom("campaign_type", e.target.value)
                        }
                      />
                      <Select
                        label={"Campaign Target *"}
                        value={from?.use_in}
                        items={campaignTargetList}
                        onChange={(e) =>
                          handleChangeFrom("use_in", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="section-group">
                    <div className="group-field">
                      <Date
                        label={"Date Start *"}
                        value={from?.campaign_start_date}
                        onChange={(date) =>
                          handleChangeFrom("campaign_start_date", date)
                        }
                      />
                      <Date
                        label={"Date End *"}
                        value={from?.campaign_end_date}
                        onChange={(date) =>
                          handleChangeFrom("campaign_end_date", date)
                        }
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <span className="title">Add Test Set</span>
                <p style={{ color: "red", width: "700px", fontWeight: "bold" }}>
                  Adaptive Test Set can not be mixed with other type of test set
                  within one campaign. Please create new campaign separately.
                </p>
                <div className="section-group">
                  <div className="group-field end">
                    <Button onClick={() => handleOpenPopupAddTestExam()}>
                      Add Test Set
                    </Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <Table
                      columns={columsTestSet}
                      rows={examset}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <span className="title">Add Custom Question</span>
                <div className="section-group">
                  <div className="group-field end">
                    <Button onClick={() => handleOpenPopupAddCustomQuestion()}>
                      Add Custom Question
                    </Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <Table
                      columns={columsCustomQuestion}
                      rows={customQuestion}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <span className="title">Additional Setting (Optional)</span>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <div className="checkBox-container">
                      <Checkbox /> Welcome video
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="browse-container">
                      <p>Browse Video (mp4)</p>
                      <ButtonUploadFile
                        defaultValue={from.vdo_url}
                        id={`upload-file`}
                        onChange={(e) => handleUploadImage(e, "vdo_url")}
                      />
                      <div>
                        <Checkbox
                          checked={from?.is_show_vdo}
                          onChange={() =>
                            handleCheckboxChange(
                              "is_show_vdo",
                              from?.is_show_vdo
                            )
                          }
                        />{" "}
                        Show Video at the end of the assessment
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      Embedded Link
                      <TextField
                        onChange={(e) =>
                          handleChangeFrom("vdo_embed", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div className="checkBox-container">
                      <Checkbox /> Anti Cheating
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div className="checkBox-container">
                      <Checkbox /> Extra Time
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      Extra Time (min)
                      <TextField
                        onChange={(e) =>
                          handleChangeFrom("extra_time", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <div className="section-group ">
                  <div className="group-field end">
                    <Button onClick={() => handleSave()}>Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <span className="title">Email Invitation</span>
                <div className="section-group">
                  <div className="group-field end">
                    <Button onClick={() => handleOpenUploadCSV()}>
                      Upload CSV
                    </Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field">
                    <TextField
                      label={"Name"}
                      value={invitationFrom.first_name}
                      onChange={(e) =>
                        handleChangeInvitationFrom("first_name", e.target.value)
                      }
                      error={errorsInvitation.first_name}
                    />
                    <TextField
                      label={"Last Name"}
                      value={invitationFrom.last_name}
                      onChange={(e) =>
                        handleChangeInvitationFrom("last_name", e.target.value)
                      }
                      error={errorsInvitation.last_name}
                    />
                    <TextField
                      label={"Email"}
                      value={invitationFrom.email}
                      onChange={(e) =>
                        handleChangeInvitationFrom("email", e.target.value)
                      }
                      error={errorsInvitation.email}
                    />
                    <Button onClick={() => handleAddInvitation()}>Add</Button>
                  </div>
                </div>
                <div className="section-group">
                  <div className="group-field end">
                    <Table
                      columns={columsInvitation}
                      rows={invitationTable}
                      onEditRow={handleEditNvite}
                      onDeleteRow={handleDeleteNvite}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className="headbar-panel-wrapper">
                <div className="section-group ">
                  <div className="group-field end">
                    <Button onClick={() => handleSaveInvitation()}>
                      Save Invitation
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default CampaignManagementCreate;
