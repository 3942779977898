import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import StatusCell from "components/table/StatusCell";

import Date from "components/form/Date";
import moment from 'moment'

import {
  getReportConfigs,
  getReportConfigById,
  clearStateReportConfig,
} from "redux/actions/report";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  reportName: "",
  reportCreateBy: "",
  reportCreateDate: null,
  reportTotalSubject: "",
};

const ConfigurationReportAll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, total, limit } = useSelector((state) => state.report);

  const [reportConfigList, setReportConfigList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, no: index++ };
    });
    setReportConfigList(newItems);
  }, [items]);

  const handleSearch = () => {
    
    setPage(0);
    dispatch(getReportConfigs(filter));
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ViewExampleReport = () => {
    return <RouterLinkCell to={``}>{"View"}</RouterLinkCell>;
  };

  const columns = [
    {
      field: "id",
      headerName: "ลำดับที่",
      width: 250,
    },
    {
      field: "name",
      headerName: "Report Name",
      width: 250,
    },
    {
      field: "createdat",
      headerName: "Created Date",
      width: 300,
    },
    { field: "createdby", headerName: "Created By", width: 250 },
    {
      field: "subjectCount",
      headerName: "จำนวนวิชา",
      width: 250,
      renderCell: IsNullValue,
    },
    {
      field: "specialSubject",
      headerName: "หัวข้อพิเศษ",
      width: 250,
      renderCell: IsNullValue,
    },
    {
      field: "exampleReport",
      headerName: "ตัวอย่าง Report",
      width: 250,
      renderCell: ViewExampleReport,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getReportConfigs(initFilter));
  };

  const handlePageChange = (nextPage) => {
    console.log(nextPage * limit);
    setPage(nextPage);
    dispatch(getReportConfigs(filter, nextPage * limit));
  };

  const handleEditConfigReport = (row) => {
    dispatch(getReportConfigById(row?.id)).then(() =>
      history.push(`/configuration-report/edit/${row?.id}`)
    );
  };

  const handleCreateReportConfig = () => {
    dispatch(clearStateReportConfig());
    history.push("/configuration-report/create");
  };

  return (
    <SidebarLayout title="Configuration Report">
      <Div>
        <SubMenu
          menu={[
            { path: "/report/all", text: "Report" },
            { path: "/campaign/all", text: "Campaign" },
            { path: "/configuration-report/all", text: "Configuration Report" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="Report Name"
                  value={filter.reportName}
                  name="search_reportname"
                  onChange={(e) =>
                    handleFilterChange("reportName", e.target.value)
                  }
                />
                <Date
                  placeholder="Created Date"
                  value={filter.reportCreateDate}
                  disableFuture
                  onChange={(date) =>
                    handleFilterChange("reportCreateDate", moment(date).format('YYYY-MM-DD'))
                  }
                />
                <TextField
                  label="Created By"
                  value={filter.reportCreateBy}
                  name="search_createdby"
                  onChange={(e) =>
                    handleFilterChange("reportCreateBy", e.target.value)
                  }
                />
              </div>
            </div>
            <br />
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Apply</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleCreateReportConfig()}>
                    Create New Report
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={reportConfigList}
              rowCount={total}
              pageSize={+limit}
              page={page}
              disableSelectionOnClick={true}
              checkboxSelection={false}
              onPageChange={handlePageChange}
              onEditRow={handleEditConfigReport}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default ConfigurationReportAll;
