import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BaseModal from "components/modal/BaseModal";
import TextField from "components/form/TextField";
import InlineSpaceText from "components/form/InlineSpaceText";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonBlack from "components/form/ButtonBlack";

import { upgradeMember } from "redux/actions/member";

import {
  selectMasterDataProvince,
  selectMasterDataDistrict,
  selectMasterDataSubDistrict,
} from "../../redux/selectors";

const companyType = [
  { value: "บริษัท จำกัด", text: "บริษัท จำกัด" },
  { value: "บริษัท มหาชน จำกัด", text: "บริษัท มหาชน จำกัด" },
  { value: "ห้างหุ้นส่วนจำกัด", text: "ห้างหุ้นส่วนจำกัด" },
  { value: "สมาคม องค์กร", text: "สมาคม องค์กร" },
  { value: "อื่นๆ", text: "อื่นๆ" },
];

const Div = styled.div`
  padding: 20px;
  width: 500px;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: "kelson_sansbold";
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    /* background: #f7f9fa;
    border-radius: 50%; */

    .form-topic {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    align-items: center;

    button {
      width: 135px;
      padding: 8px;
    }
  }
`;

const PopupUpgradeBusinessMember = ({
  open,
  onClose,
  label,
  dataEdit = {},
  isUploading,
  maxWidth,
  fileName,
  percentage,
  error,
  member_id,
}) => {
  const dispatch = useDispatch();
  const masterDataProvince = useSelector(selectMasterDataProvince);
  const masterDataDistrict = useSelector(selectMasterDataDistrict);
  const masterDataSubDistrict = useSelector(selectMasterDataSubDistrict);

  const [dataProvince, setDataProvince] = useState(masterDataProvince);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubdistrict, setDataSubDistrict] = useState([]);

  const [name, setName] = useState("");
  const [organizationId, setOrganizationId] = useState();
  const [organizationType, setOrganizationType] = useState("");

  const [address, setAddress] = useState("");
  const [road, setRoad] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subdistrict, setSubdistrict] = useState("");
  const [postcode, setPostcode] = useState("");

  const [errors, setErrors] = useState({});

  const handleProvinceChange = (value) => {
    console.log(value);
    const districts = masterDataDistrict
      .map((it) => it)
      .filter((d) => d.province_id === +value);
    setDataDistrict(districts);
    setProvince(value);
    validate({ province: value });
  };

  const handleCompanyTypeChange = (value) => {
    setOrganizationType(value);
  };

  const handleDistrictChange = (value) => {
    const subDistricts = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.district_id === +value);
    setDataSubDistrict(subDistricts);
    setDistrict(value);
    validate({ district: value });
  };
  const handleSubDistrictChange = (value) => {
    setSubdistrict(value);
    handlePostcodeChange(value);
    validate({ subdistrict: value });
  };

  const handlePostcodeChange = (value) => {
    const postcode = masterDataSubDistrict
      .map((it) => it)
      .filter((s) => s.value === +value)
      .map((z) => z.postcode)[0];
    setPostcode(postcode);
    validate({ postcode: value });
  };

  const submitUpgradeMember = () => {
    if (validate()) {
      const obj = {
        member_id: member_id,
        name: name,
        organization_id: organizationId,
        organization_type: organizationType,
        address: [
          {
            address_type: 1,
            address: address,
            sub_district_id: +subdistrict,
            district_id: +district,
            province_id: province,
            zip_code: postcode,
          },
        ],
      };

      // console.log(obj);

      dispatch(upgradeMember(obj));
      onClose();
    }
  };

  const validate = (
    fieldValues = {
      name,
      organizationId,
      organizationType,
      address,
      road,
      province,
      district,
      subdistrict,
      postcode,
    }
  ) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name !== "" ? "" : "กรุณากรอกชื่อบริษัท";
    if ("organizationId" in fieldValues)
      temp.organizationId =
        fieldValues.organizationId !== "" ? "" : "กรุณาเลือกประเภทบริษัท";
    if ("organizationType" in fieldValues)
      temp.organizationType =
        fieldValues.organizationType !== ""
          ? ""
          : "กรุณากรอกเลขประจำตัวผู้เสียภาษี";
    if ("address" in fieldValues)
      temp.address =
        fieldValues.address !== "" ? "" : "กรุณากรอกที่อยู่ปัจจุบัน";
    if ("road" in fieldValues)
      temp.road = fieldValues.road !== "" ? "" : "กรุณาเลือกถนน";
    if ("province" in fieldValues)
      temp.province = fieldValues.province !== "" ? "" : "กรุณาเลือกจังหวัด";
    if ("district" in fieldValues)
      temp.district = fieldValues.district !== "" ? "" : "กรุณาเลือกอำเภอ";
    if ("subdistrict" in fieldValues)
      temp.subdistrict = fieldValues.subdistrict !== "" ? "" : "กรุณาเลือกตำบล";
    if ("postcode" in fieldValues)
      temp.postcode =
        fieldValues.postcode !== "" ? "" : "กรุณากรอกรหัสไปรษณีย์";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {}, []);

  return (
    <BaseModal open={open} onClose={onClose} maxWidth={maxWidth}>
      <Div>
        <div className="form-wrapper">
          <div className="title">Upgrade Member To Bussiness</div>

          <p className="form-topic">ข้อมูล</p>
          <InlineSpaceText label="ชื่อบริษัท">
            <TextField
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                validate({ [name]: e.target.value });
              }}
              className="field-name"
              error={errors.name}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ประเภทบริษัท">
            <Select
              items={companyType}
              value={organizationType}
              onChange={(e) => handleCompanyTypeChange(e.target.value)}
              defaultValue="เลือกประเภทบริษัท"
              className="field-companyType"
              id="company-type"
            />
          </InlineSpaceText>
          <InlineSpaceText label="เลขประจำตัวผู้เสียภาษี">
            <TextField
              value={organizationId}
              onChange={(e) => {
                setOrganizationId(e.target.value);
                validate({ [organizationId]: e.target.value });
              }}
              className="field-organizationId"
              error={errors.organizationId}
            />
          </InlineSpaceText>

          <p className="form-topic">ที่อยู่</p>
          <InlineSpaceText label="ที่อยู่ปัจุบัน">
            <TextField
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                validate({ ["address"]: e.target.value });
              }}
              className="field-address"
              id="address-address"
              error={errors.address}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ถนน">
            <TextField
              value={road}
              onChange={(e) => {
                setRoad(e.target.value);
                validate({ ["road"]: e.target.value });
              }}
              className="field-road"
              id="address-road"
              error={errors.road}
            />
          </InlineSpaceText>
          <InlineSpaceText label="จังหวัด">
            <Select
              items={dataProvince}
              value={province}
              onChange={(e) => handleProvinceChange(e.target.value)}
              defaultValue="เลือกจังหวัด"
              className="field-province"
              id="address-province"
              errorText={errors.province}
              isError={errors.province}
            />
          </InlineSpaceText>
          <InlineSpaceText label="อำเภอ">
            <Select
              items={dataDistrict}
              value={district}
              onChange={(e) => handleDistrictChange(e.target.value)}
              defaultValue="เลือกอำเภอ"
              className="field-district"
              id="address-district"
              errorText={errors.district}
              isError={errors.district}
            />
          </InlineSpaceText>
          <InlineSpaceText label="ตำบล">
            <Select
              items={dataSubdistrict}
              value={subdistrict}
              onChange={(e) => handleSubDistrictChange(e.target.value)}
              defaultValue="เลือกตำบล"
              className="field-subdistrict"
              id="address-subdistrict"
              errorText={errors.subdistrict}
              isError={errors.subdistrict}
            />
          </InlineSpaceText>
          <InlineSpaceText label="รหัสไปรษณีย์">
            <TextField
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
                validate({ ["postcode"]: e.target.value });
              }}
              className="field-postcode"
              id="address-postcode"
              error={errors.postcode}
            />
          </InlineSpaceText>
        </div>

        <div className="button-wrapper">
          <Button onClick={onClose} outlined>
            Cancel{" "}
          </Button>
          <ButtonBlack type="submit" onClick={() => submitUpgradeMember()}>
            Confrim
          </ButtonBlack>
        </div>
      </Div>
    </BaseModal>
  );
};

export default PopupUpgradeBusinessMember;
