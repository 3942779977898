import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import SidebarLayout from "components/layout/SidebarLayout";
import SubMenu from "components/layout/SubMenu";
import Select from "components/form/Select";
import TextField from "components/form/TextField";
import Button from "components/form/Button";
import Table from "components/table/Table";
import RouterLinkCell from "components/table/RouterLinkCell";
import PopupAddBusinessMember from "containers/popup/PopupAddBusinessMember";
import PopupAddIndividualMember from "containers/popup/PopupAddIndividualMember";
import PopupApproveDocument from "containers/popup/PopupApproveDocument";

import { getMember } from "redux/actions/member";
import { ContactsTwoTone } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import PopupUpgradeTesterMember from "containers/popup/PopupUpgradeTesterMember";
import PopupUpgradeBusinessMember from "containers/popup/PopupUpgradeBusinessMember";

const Div = styled.div`
  .content-wrapper {
    margin-top: 29px;
    padding: 36px;
    background: #fff;
    border-radius: 8px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35vh;

    .section-group {
      margin-bottom: 36px;

      .group-title {
        font-size: 20px;
        font-weight: bold;
      }

      .group-field {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin: 0 -10px;

        &.end {
          justify-content: flex-end;
        }

        > * {
          width: 260px;
          min-width: 260px;
          margin-top: 16px;
          padding: 0 10px;
        }

        button {
          margin: 0 10px;
          width: auto;
          min-width: 120px;
          height: 47px;
        }
      }
    }
  }

  .table-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e8ecef;

    .table-header {
      margin-bottom: 26px;

      .row {
        display: flex;
        margin-top: 10px;

        .label {
          font-weight: bold;
          width: 200px;
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
`;

const initFilter = {
  idCard: "",
  first_name: "",
  last_name: "",
  email: "",
  organization_name: "",
  member_type_name: "",
};

const memberTypeList = [
  { value: "tester", text: "Tester" },
  { value: "member", text: "Member" },
  { value: "business", text: "Business" },
];

const MemberAll = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, total, limit } = useSelector((state) => state.member);

  const [memberList, setMemberList] = useState(items);
  const [filter, setFilter] = useState(initFilter);
  const [page, setPage] = useState(0);
  const [selectedMemberId, setSelectedMemberId] = useState([]);

  const [isOpenAddIndividualModal, setIsOpenAddIndividualModal] =
    useState(false);
  const [isOpenAddBusinessModal, setIsOpenAddBusinessModal] = useState(false);
  const [isOpenApproveDocumentModal, setIsOpenApproveDocumentModal] =
    useState(false);
  const [isOpenUpgradeTesterMemberModal, setIsUpgradeTesterMemberModal] =
    useState(false);
  const [isUpgradeMemberModal, setIsUpgradeMemberModal] = useState(false);
  const [isOpenPopupStatus, setIsOpenPopupStatus] = useState(false);
  const [memberId, setMemberId] = useState();

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    let index = 1;
    let newItems = items.map((it) => {
      return { ...it, no: index++ };
    });
    setMemberList(newItems);
  }, [items]);

  const LinkDetailCell = ({ value, row }) => {
    return (
      <RouterLinkCell to={`/member/detail/${row?.id}`}>{value}</RouterLinkCell>
    );
  };

  const IsNullValue = ({ value }) => {
    if (value === null || !value) return "-";
    return value;
  };

  const ActionUpgradeUserCell = ({ row }) => {
    if (row?.member_type_name === "tester") {
      return (
        <Button
          style={{ width: "100%" }}
          onClick={() => handleOpenUpgradeTesterMemberPopup(row?.id)}
        >
          Upgrade
        </Button>
      );
    }
    return (
      <Button
        style={{ width: "100%" }}
        onClick={() => handleOpenUpgradeMemberPopup(row?.id)}
        disabled={row?.member_type_name === "business"}
      >
        Upgrade
      </Button>
    );
  };

  const columns = [
    {
      field: "no",
      headerName: "ลำดับ",
      width: 80,
    },
    {
      field: "id",
      headerName: "Member ID",
      width: 200,
      renderCell: LinkDetailCell,
    },
    { field: "first_name", headerName: "ชื่อ", width: 200 },
    { field: "last_name", headerName: "นามสกุล", width: 200 },
    {
      field: "email",
      headerName: "Email",
      renderCell: IsNullValue,
      width: 300,
    },
    {
      field: "phone",
      headerName: "Phone",
      renderCell: IsNullValue,
      width: 200,
    },
    {
      field: "organization_name",
      headerName: "Organization",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "member_type_name",
      headerName: "Member Type",
      width: 200,
      renderCell: IsNullValue,
    },
    {
      field: "register_date",
      headerName: "Registered Date",
      width: 250,
    },
    {
      field: "upgradeUser",
      headerName: "",
      renderCell: ActionUpgradeUserCell,
      width: 200,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleClearFilter = () => {
    setFilter(initFilter);
    dispatch(getMember({ ...initFilter, skip: page * 10 }));
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    dispatch(getMember({ ...filter, skip: nextPage * 10 }));
  };

  const handleSearch = () => {
    setPage(0);
    dispatch(getMember({ ...filter, skip: page * 10 }));
  };

  const handleCloseIndividualPopup = () => {
    setTimeout(() => {
      handleSearch();
    }, 500);
    setIsOpenAddIndividualModal(false);
  };

  const handleCloseBusinessPopup = () => {
    setIsOpenAddBusinessModal(false);
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  const handleCloseApprovePopup = () => {
    setIsOpenApproveDocumentModal(false);
    setTimeout(() => {
      handleSearch();
    }, 2000);
  };

  const handleAddIndividualMember = () => {
    setIsOpenAddIndividualModal(true);
  };

  const handleAddBusinessMember = () => {
    setIsOpenAddBusinessModal(true);
  };

  const handleOpenPopupStatus = () => {
    setIsOpenPopupStatus(true);
  };

  const handleClosePopupStatus = () => {
    setIsOpenPopupStatus(false);
    window.location.reload();
  };

  const handleSelectMember = (idList) => {
    let newArr = [];
    for (const id of idList) {
      let item = memberList.find((it) => it.id === id);
      newArr.push({
        id: item?.id,
        name: `${item?.first_name} ${item?.last_name}`,
        status: item?.memberStatus,
      });
    }

    setSelectedMemberId(newArr);
  };

  const handleDeleteMember = () => {};

  const handleOpenUpgradeTesterMemberPopup = (id) => {
    setMemberId(id);
    setIsUpgradeTesterMemberModal(true);
  };

  const handleCloseUpgradeTesterMemberPopup = (id) => {
    setIsUpgradeTesterMemberModal(false);
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  const handleOpenUpgradeMemberPopup = (id) => {
    setMemberId(id);
    setIsUpgradeMemberModal(true);
  };

  const handleCloseUpgradeMemberPopup = (id) => {
    setIsUpgradeMemberModal(false);
    setTimeout(() => {
      handleSearch();
    }, 500);
  };

  return (
    <SidebarLayout title="Member Management">
      {Boolean(isOpenAddBusinessModal) && (
        <PopupAddBusinessMember open onClose={handleCloseBusinessPopup} />
      )}
      {Boolean(isOpenAddIndividualModal) && (
        <PopupAddIndividualMember open onClose={handleCloseIndividualPopup} />
      )}
      {Boolean(isOpenApproveDocumentModal) && (
        <PopupApproveDocument
          maxWidth={"lg"}
          open
          onClose={handleCloseApprovePopup}
        />
      )}
      {Boolean(isOpenUpgradeTesterMemberModal) && (
        <PopupUpgradeTesterMember
          member_id={memberId}
          open
          onClose={handleCloseUpgradeTesterMemberPopup}
        />
      )}
      {Boolean(isUpgradeMemberModal) && (
        <PopupUpgradeBusinessMember
          member_id={memberId}
          open
          onClose={handleCloseUpgradeMemberPopup}
        />
      )}
      <Div>
        <SubMenu
          menu={[
            { path: "/member/all", text: "All" },
            { path: "/member/segment", text: "Segment" },
          ]}
        />
        <div className="content-wrapper">
          <div className="form-wrapper">
            <div className="section-group">
              <div className="group-title">Search</div>
              <div className="group-field">
                <TextField
                  label="ชื่อ"
                  value={filter.first_name}
                  onChange={(e) =>
                    handleFilterChange("first_name", e.target.value)
                  }
                  placeholder={"กรอกชื่อจริง"}
                  name="first_name"
                />
                <TextField
                  label="นามสกุล"
                  value={filter.last_name}
                  onChange={(e) =>
                    handleFilterChange("last_name", e.target.value)
                  }
                  placeholder={"กรอกนามสกุล"}
                  name="last_name"
                />
                <TextField
                  label="อีเมล์"
                  value={filter.email}
                  onChange={(e) => handleFilterChange("email", e.target.value)}
                  placeholder={"กรอกอีเมล์"}
                  name="email"
                />
                <TextField
                  label="ชื่อบริษัท"
                  value={filter.organization_name}
                  onChange={(e) =>
                    handleFilterChange("organization_name", e.target.value)
                  }
                  placeholder={"กรอกชื่อบริษัท"}
                  name="organization_name"
                />
                <Select
                  label={"Member Type"}
                  value={filter.member_type_name}
                  items={memberTypeList}
                  onChange={(e) =>
                    handleFilterChange("member_type_name", e.target.value)
                  }
                  defaultValue={"เลือกประเภทสมาชิก"}
                  name="member_type_name"
                />
              </div>
            </div>
            <div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button onClick={() => handleSearch()}>Apply</Button>
                  <Button onClick={() => handleClearFilter()} outlined>
                    Clear
                  </Button>
                </div>
              </div>
              <div className="section-group ">
                <div className="group-field end">
                  <Button
                    disabled={selectedMemberId.length === 0}
                    onClick={handleOpenPopupStatus}
                  >
                    change stutus member
                  </Button>
                  <Button
                    id="btn-add-member-individual"
                    onClick={handleAddIndividualMember}
                  >
                    Add Individual Member
                  </Button>
                  <Button
                    id="btn-add-member-business"
                    onClick={handleAddBusinessMember}
                  >
                    Add Business Member
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              columns={columns}
              rows={memberList}
              rowCount={total}
              pageSize={limit}
              page={page}
              onPageChange={handlePageChange}
              onSelectionChange={handleSelectMember}
              disableSelectionOnClick={true}
              // onEditRow={handleEditMember}
              onDeleteRow={handleDeleteMember}
            />
          </div>
        </div>
      </Div>
    </SidebarLayout>
  );
};

export default MemberAll;
