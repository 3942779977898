const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const CLEAR_ALERT = "CLEAR_ALERT";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const LOGIN = genRequest("LOGIN");

export const GET_MASTER_DATA_PROVINCE = "GET_MASTER_DATA_PROVINCE";
export const GET_MASTER_DATA_DISTRICT = "GET_MASTER_DATA_DISTRICT";
export const GET_MASTER_DATA_SUB_DISTRICT = "GET_MASTER_DATA_SUB_DISTRICT";
export const GET_MASTER_DATA_BANK = "GET_MASTER_DATA_BANK";
export const GET_MASTER_DATA_NATIONALITY = "GET_MASTER_DATA_NATIONALITY";
export const GET_MASTER_DATA_TAG = "GET_MASTER_DATA_TAG";
export const GET_MASTER_DATA_SUBJECT = "GET_MASTER_DATA_SUBJECT";
export const GET_MASTER_DATA_JOB = "GET_MASTER_DATA_JOB";
export const GET_MASTER_DATA_JOB_LEVEL = "GET_MASTER_DATA_JOB_LEVEL";
export const GET_MASTER_DATA_ORGANIZATION = "GET_MASTER_DATA_ORGANIZATION";
export const GET_MASTER_DATA_COMPANY = "GET_MASTER_DATA_COMPANY";

export const ADD_MEMBER = genRequest("ADD_MEMBER");
export const EDIT_MEMBER_INFO = genRequest("EDIT_MEMBER_INFO");
export const EDIT_PERSONAL_INFO = genRequest("EDIT_PERSONAL_INFO");
export const EDIT_MEMBER_STATUS = genRequest("EDIT_MEMBER_STATUS");
export const UPLOAD_IMAGE = genRequest("UPLOAD_IMAGE");
export const GET_MEMBER_LIST = genRequest("GET_MEMBER_LIST");
export const GET_MEMBER_BY_ID = genRequest("GET_MEMBER_BY_ID");
export const RESEND_VERIFY_EMAIL = genRequest("RESEND_VERIFY_EMAIL");
export const FORGET_PASSWORD = genRequest("FORGET_PASSWORD");
export const APPROVE_DOCUMENT_MEMBER = "APPROVE_DOCUMENT_MEMBER";
export const REQUEST_DOCUMENT_MEMBER = "REQUEST_DOCUMENT_MEMBER";

export const ADD_SEGMENT = genRequest("ADD_SEGMENT");
export const ADD_USER_SEGMENT = genRequest("ADD_USER_SEGMENT");
export const PATCH_SEGMENT = genRequest("PATCH_SEGMENT");
export const DELETE_SEGMENT_ITEM = genRequest("DELETE_SEGMENT_ITEM");
export const DELETE_USER_SEGMENT_ITEM = genRequest("DELETE_USER_SEGMENT_ITEM");
export const GET_SEGMENT_LIST = genRequest("GET_SEGMENT_LIST");
export const GET_SEGMENT_BY_ID = genRequest("GET_SEGMENT_BY_ID");

export const GET_PRODUCT_LIST = genRequest("GET_PRODUCT_LIST");
export const ADD_QUOTATION = genRequest("ADD_QUOTATION");
export const CREATE_INVOICE = genRequest("CREATE_INVOICE");
export const CREATE_RECEIPT = genRequest("CREATE_RECEIPT");
export const CREATE_DELIVERY_NOTE = genRequest("CREATE_DELIVERY_NOTE");
export const GET_INVOICE_BY_ID = genRequest("GET_INVOICE_BY_ID");
export const GET_QUOTATION_BY_ID = genRequest("GET_QUOTATION_BY_ID");
export const GET_RECEIPT_BY_ID = genRequest("GET_RECEIPT_BY_ID");
export const GET_DELIVERY_BY_ID = genRequest("GET_DELIVERY_BY_ID");
export const GET_QUOTATION_LIST = genRequest("GET_QUOTATION_LIST");
export const GET_INVOICE_LIST = genRequest("GET_INVOICE_LIST");
export const PATCH_INVOICE_STATUS = genRequest("PATCH_INVOICE_STATUS");

export const CREATE_EXAM_CAMPAIGN = genRequest("CREATE_EXAM_CAMPAIGN");
export const GET_EXAM_CAMPAIGN_LIST = genRequest("GET_EXAM_CAMPAIGN_LIST");
export const GET_EXAM_CAMPAIGN_BY_DATE = genRequest(
  "GET_EXAM_CAMPAIGN_BY_DATE"
);
export const PATCH_EXAM_STATUS = genRequest("PATCH_EXAM_STATUS");
export const CREATE_REPORT_CONFIG = genRequest("CREATE_REPORT_CONFIG");
export const GET_DISC_RESULT_LIST = genRequest("GET_DISC_RESULT_LIST");
export const GET_REPORT_CONFIG_LIST = genRequest("GET_REPORT_CONFIG_LIST");
export const GET_REPORT_CONFIG_BY_ID = genRequest("GET_REPORT_CONFIG_BY_ID");
export const PATCH_REPORT_CONFIG = genRequest("PATCH_REPORT_CONFIG");
export const GET_CAMPAIGN_LIST = genRequest("GET_CAMPAIGN_LIST");
export const GET_REPORT_CONFIG_LIST_DROPDOWN = genRequest(
  "GET_REPORT_CONFIG_LIST_DROPDOWN"
);
export const PATCH_EXAM_CAMPAIGN_STATUS = genRequest(
  "PATCH_EXAM_CAMPAIGN_STATUS"
);

export const GET_EXAM_USER_LIST = genRequest("GET_EXAM_USER_LIST");
export const GET_EXAM_USER_DETAIL = genRequest("GET_EXAM_USER_DETAIL");
export const EDIT_EXAM_USER_DETAIL = genRequest("EDIT_EXAM_USER_DETAIL");
export const GET_EXAM_USER_DETAIL_SUBJECT_LIST = genRequest(
  "GET_EXAM_USER_DETAIL_SUBJECT_LIST"
);

export const GET_EXAM_QUESTION_LIST = genRequest("GET_EXAM_QUESTION_LIST");
export const GET_EXAM_QUESTION_BY_ID = genRequest("GET_EXAM_QUESTION_BY_ID");
export const GET_EXAM_QUESTION_CHOICE_BY_ID = genRequest(
  "GET_EXAM_QUESTION_CHOICE_BY_ID"
);
export const CREATE_EXAM_QUESTION = genRequest("CREATE_EXAM_QUESTION");
export const PATCH_EXAM_QUESTION_CHOICE_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_CHOICE_BY_ID"
);
export const PATCH_EXAM_QUESTION_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_BY_ID"
);
export const PATCH_EXAM_QUESTION_STATUS_BY_ID = genRequest(
  "PATCH_EXAM_QUESTION_STATUS_BY_ID"
);

export const APPROVE_EXAM_QUESTION = genRequest("APPROVE_EXAM_QUESTION");
export const GET_EXAM_SET_LIST = genRequest("GET_EXAM_SET_LIST");
export const GET_RANDOM_EXAM = genRequest("GET_RANDOM_EXAM");
export const GET_ALL_QUESTION_ACTIVE = genRequest("GET_ALL_QUESTION_ACTIVE");
export const GET_CLIENT_LIBRARY_LIST = genRequest("GET_CLIENT_LIBRARY_LIST");
export const CREATE_EXAM_SET = genRequest("CREATE_EXAM_SET");
export const GET_EXAM_SET_BY_ID = genRequest("GET_EXAM_SET_BY_ID");
export const PATCH_EXAM_SET = genRequest("PATCH_EXAM_SET");
export const CLEAR_EXAM_SET = "CLEAR_EXAM_SET";
export const GET_ALL_DATA_EXAM_QUESTION_LIST = genRequest(
  "GET_ALL_DATA_EXAM_QUESTION_LIST"
);

export const EXAM_IMPORT_STORAGE = "EXAM_IMPORT_STORAGE";
export const CLEAR_EXAM_IMPORT_STORAGE = "CLEAR_EXAM_IMPORT_STORAGE";
export const GET_EXAM_IMPORT_STORAGE_BY_ID = "GET_EXAM_IMPORT_STORAGE_BY_ID";
export const GET_EXAM_CHOICE_IMPORT_STORAGE_BY_ID =
  "GET_EXAM_CHOICE_IMPORT_STORAGE_BY_ID";
export const UPDATE_EXAM_CHOICE_IMPORT_STORAGE_BY_ID =
  "UPDATE_EXAM_CHOICE_IMPORT_STORAGE_BY_ID";
export const MIGRATE_EXAM_IMPORT = genRequest("MIGRATE_EXAM_IMPORT");
export const DELETE_EXAM_QUESTION_BY_ID = genRequest(
  "DELETE_EXAM_QUESTION_BY_ID"
);
export const DELETE_EXAM_SET_BY_ID = genRequest("DELETE_EXAM_SET_BY_ID");
export const CREATE_CUSTOM_QUESTION = genRequest("CREATE_CUSTOM_QUESTION");
export const GET_CUSTOM_QUESTION = genRequest("GET_CUSTOM_QUESTION");
export const GET_CUSTOMER_QUESTION_BY_ID = genRequest(
  "GET_CUSTOMER_QUESTION_BY_ID"
);
export const PATCH_CUSTOMER_QUESTION_BY_ID = genRequest(
  "PATCH_CUSTOMER_QUESTION_BY_ID"
);
export const GET_SUBJECT_LIST = genRequest("GET_SUBJECT_LIST");
export const GET_SUBJECT_BY_ID = genRequest("GET_SUBJECT_BY_ID");
export const CREATE_SUBJECT = genRequest("CREATE_SUBJECT");
export const PATCH_SUBJECT = genRequest("PATCH_SUBJECT");
export const DELETE_SUBJECT = genRequest("DELETE_SUBJECT");

export const CREATE_CAMPAIGN = genRequest("CREATE_CAMPAIGN");
export const DELETE_CAMPAIGN_BY_ID = genRequest("DELETE_CAMPAIGN_BY_ID");
export const PATCH_CAMPAIGN_BY_ID = genRequest("PATCH_CAMPAIGN_BY_ID");
export const GET_CUSTOM_QUESTION_LIST = genRequest("GET_CUSTOM_QUESTION_LIST");
export const GET_CAMPAIGN_BY_ID = genRequest("GET_CAMPAIGN_BY_ID");
export const GET_CAMPAIGN_MANAGEMENT_LIST = genRequest(
  "GET_CAMPAIGN_MANAGEMENT_LIST"
);
export const GET_TEST_EXAM_LIST = genRequest("GET_TEST_EXAM_LIST");
export const ADD_TEST_EXAM_STORAGE = "ADD_TEST_EXAM_STORAGE";
export const ADD_CUSTOM_QUESTION_STORAGE = "ADD_CUSTOM_QUESTION_STORAGE";
export const CLEAR_TEST_EXAM_STORAGE = "CLEAR_TEST_EXAM_STORAGE";
export const CLEAR_CUSTOM_QUESTION_STORAGE = "CLEAR_CUSTOM_QUESTION_STORAGE";
export const CREATE_NVITE_EXAMER = genRequest("CREATE_NVITE_EXAMER");
export const ADD_INVITE_STORAGE = "ADD_INVITE_STORAGE";
export const DELETE_CUSTOMER_QUESTION = genRequest("DELETE_CUSTOMER_QUESTION");

export const GET_DETAIL_QUESTION_BY_CAMPAIGN = genRequest(
  "GET_DETAIL_QUESTION_BY_CAMPAIGN"
);
export const GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN = genRequest(
  "GET_DETAIL_CUSTOM_QUESTION_BY_CAMPAIGN"
);
export const TESTER_UPGRADE = genRequest("TESTER_UPGRADE");
export const MEMBER_UPGRADE = genRequest("MEMBER_UPGRADE");

export const GET_ALL_QUESTION = genRequest("GET_ALL_QUESTION");
